<!DOCTYPE html>
<html lang=en>
<meta charset=utf-8>
<title>Form by LittleTools</title>
<meta name=viewport content="width=device-width, initial-scale=1.0">
<link rel=stylesheet href="https://cdn.jsdelivr.net/npm/water.css@2/out/water.css">
<style>
	label {
		display: block;
	}
    input {
        display: inline-block;
    }
</style>
<form method="<%= method %>" action="<%= escapeForHTMLAttribute(action) %>" enctype="<%= enctype %>">
<% for (const f of fields) { %>
<label>
	<span><%= f.name %></span>
	<input
			name="<%= escapeForHTMLAttribute(f.name) %>"
			<% if (f.isFile()) { %>
			type=file
			<% } else { %>
			type=text
			value="<%= escapeForHTMLAttribute(f.value) %>"
			<% } %>
	>
</label>
<% } %>
<p><button type=submit style="font-size: 2em">Submit</button></p>
</form>
<p>Form generated by <a href="https://littletools.app/form">LittleTools</a>.</p>
<script>
	const fileFields = <%= JSON.stringify(fields.filter(f => f.isFile()).map(f => [f.name, f.value])) %>
	for (const [name, value] of fileFields) {
		const input = document.querySelector("input[name='" + name + "']")
		const file = new File([value], name)
		const container = new DataTransfer()
		container.items.add(file)
		input.files = container.files
	}
</script>
