// This file was generated by lezer-generator. You probably shouldn't edit it.
import {LRParser} from "@lezer/lr"
export const parser = LRParser.deserialize({
  version: 14,
  states: "%|OQOPOOOVOPO'#C_O_OPO'#C^OdOPO'#CcOiOPOOOnOPO,58xOsOPO,58}OxOQOOOOOO'#C`'#C`O}OPO1G.dOOOO'#Ce'#CeO!SOPO1G.iOOOO'#Cl'#ClQOOOOOO!XOPO7+$OO!^OPO7+$TOOOO'#Ca'#CaO!cOPO<<GjOOOO'#Cf'#CfO!hOPO<<GoO!mOPOAN=UO!rOPOAN=ZOOOO'#Cb'#CbOOOOG22pG22pOOOO'#Cg'#CgO!wOPOG22uO!|OPOLD(aOOOO'#Ch'#ChO#ROPO!$'K{O#WOPO!)9AgOOOO'#Ci'#CiO#]OPO!.K7RO#bOPO!4/,mOOOO'#Cj'#CjO#gOPO!9A!XO#lOPO!?$EsOOOO'#Ck'#CkOOOO!D6;_!D6;_",
  stateData: "#q~ObPO~OcRXdWX~OcTO~OdUO~OeVO~ObWO~ObYO~Of[O~Oc^O~Od_O~Ob`O~ObbO~OcdO~OdeO~ObfO~ObhO~OdjO~ObkO~OdmO~ObnO~OdpO~ObqO~OdsO~ObtO~O",
  goto: "!^aPPbehknbqtwz}!Q!T!W!ZRSORQORXTRa^RgdRRORZURc_RieRljRomRrpRusR]V",
  nodeNames: "⚠ CIDR Address4 N1 N2 N3 N4 Address6 N1 N2 N3 N4 N5 N6 N7 N8 ReservedBitCount",
  maxTerm: 22,
  skippedNodes: [0],
  repeatNodeCount: 0,
  tokenData: "!Z~RT!O!Pb!P!Qg!Q![l![!]!U#T#Zy~gOc~~lOe~RsQbPfQ!Q![l#T#ZyP!OQbP!Q![y#T#Zy~!ZOd~",
  tokenizers: [0, 1],
  topRules: {"CIDR":[0,1]},
  tokenPrec: 0
})
