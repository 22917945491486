const modules = {}
import m0 from "src/tools/annotate.ts"
modules["annotate.ts"] = {default: m0}
import m1 from "src/tools/base64.ts"
modules["base64.ts"] = {default: m1}
import m2 from "src/tools/char-codes.ts"
modules["char-codes.ts"] = {default: m2}
import m3 from "src/tools/cidr.ts"
modules["cidr.ts"] = {default: m3}
import m4 from "src/tools/clickjacking.ts"
modules["clickjacking.ts"] = {default: m4}
import m5 from "src/tools/content-security-policy.ts"
modules["content-security-policy.ts"] = {default: m5}
import m6 from "src/tools/cron.ts"
modules["cron.ts"] = {default: m6}
import m7 from "src/tools/csv.ts"
modules["csv.ts"] = {default: m7}
import m8 from "src/tools/datetime.ts"
modules["datetime.ts"] = {default: m8}
import m9 from "src/tools/diff.ts"
modules["diff.ts"] = {default: m9}
import m10 from "src/tools/dkim.ts"
modules["dkim.ts"] = {default: m10}
import m11 from "src/tools/dns.ts"
modules["dns.ts"] = {default: m11}
import m12 from "src/tools/embed.ts"
modules["embed.ts"] = {default: m12}
import m13 from "src/tools/form.ts"
modules["form.ts"] = {default: m13}
import m14 from "src/tools/iframe.ts"
modules["iframe.ts"] = {default: m14}
import m15 from "src/tools/js.ts"
modules["js.ts"] = {default: m15}
import m16 from "src/tools/json.ts"
modules["json.ts"] = {default: m16}
import m17 from "src/tools/keyboard-tester.ts"
modules["keyboard-tester.ts"] = {default: m17}
import m18 from "src/tools/lezer.ts"
modules["lezer.ts"] = {default: m18}
import m19 from "src/tools/notepad.ts"
modules["notepad.ts"] = {default: m19}
import m20 from "src/tools/oauth2-client.ts"
modules["oauth2-client.ts"] = {default: m20}
import m21 from "src/tools/oauth2-result.ts"
modules["oauth2-result.ts"] = {default: m21}
import m22 from "src/tools/oidc-provider-authorize.ts"
modules["oidc-provider-authorize.ts"] = {default: m22}
import m23 from "src/tools/oidc-provider.ts"
modules["oidc-provider.ts"] = {default: m23}
import m24 from "src/tools/pdf-remove-password.ts"
modules["pdf-remove-password.ts"] = {default: m24}
import m25 from "src/tools/rot13.ts"
modules["rot13.ts"] = {default: m25}
import m26 from "src/tools/saml-provider-authorize.ts"
modules["saml-provider-authorize.ts"] = {default: m26}
import m27 from "src/tools/saml-provider.ts"
modules["saml-provider.ts"] = {default: m27}
import m28 from "src/tools/send-mail.ts"
modules["send-mail.ts"] = {default: m28}
import m29 from "src/tools/smtp-servers.ts"
modules["smtp-servers.ts"] = {default: m29}
import m30 from "src/tools/svg-preview.ts"
modules["svg-preview.ts"] = {default: m30}
import m31 from "src/tools/timer.ts"
modules["timer.ts"] = {default: m31}
import m32 from "src/tools/unix-permissions.ts"
modules["unix-permissions.ts"] = {default: m32}
import m33 from "src/tools/url-encode.ts"
modules["url-encode.ts"] = {default: m33}
import m34 from "src/tools/vnode.ts"
modules["vnode.ts"] = {default: m34}
export default modules